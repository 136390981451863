<template>
  <div>
    <b-card no-body class="mb-0">
      <!-- <div class="p-1">
        <b-row>
          <b-col
            v-if="$can('create', 'info')"
            class="col d-flex justify-content-end"
          >
            <b-button variant="primary" @click="addAgencyRadio">
              <feather-icon icon="PlusIcon" size="16" /> {{ $t("New") }}
            </b-button>
          </b-col>
        </b-row>
      </div> -->
      <b-table
        ref="refAgencyRadioListTable"
        class="position-relative table-white-space"
        :items="fetchAgencyRadio"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(balance_type_id)="data">
          <div class="cursor-pointer text-white">
            {{ data.item.balance_type_id }}
          </div>
        </template>

        <template #cell(level_id)="data">
          <div class="cursor-pointer text-white">
            {{ data.item.level_id }}
          </div>
        </template>

        <template #cell(ratio)="data">
          <div class="cursor-pointer text-white">
            {{ data.item.ratio }}
          </div>
        </template>

        <template #cell(discount)="data">
          {{ data.item.discount }}
        </template>

        <template #cell(third_partner)="data">
          <div v-if="data.item.third_partner" class="cursor-pointer text-white">
            {{ data.item.third_partner.name }}
          </div>
        </template>

        <template #cell(level)="data">
          <div v-if="data.item.level" class="cursor-pointer text-white">
            {{ data.item.level.name_en }}
          </div>
        </template>

        <template #cell(created_at)="data">
          {{
            data.item.created_at
              ? formatDateTime(data.item.created_at).replace(".000000Z", "")
              : ""
          }}
        </template>

        <template #cell(updated_at)="data">
          {{
            data.item.updated_at
              ? formatDateTime(data.item.updated_at).replace(".000000Z", "")
              : ""
          }}
        </template>

        <!-- <template #cell(action)="data">
          <b-button
            v-b-tooltip.hover.v-info
            :title="$t('Edit')"
            v-if="$can('update', 'info')"
            variant="primary"
            class="btn-icon btn-sm mr-50"
            @click="updateAgencyRadio(data.item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.v-danger
            :title="$t('Reject')"
            v-if="$can('view', 'infodelete')"
            variant="danger"
            class="btn-icon btn-sm"
            @click="deleteAgencyRadio(data.item.id)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </template> -->
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalAgencyRadio"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Add AgencyRadio Modal -->
    <!-- <add-agency-radio-modal @refetch-data="refetchData" /> -->

    <!-- Update AgencyRadio Modal -->
    <!-- <update-agency-radio-modal
      :agency-radio-detail.sync="agencyRadioDetail"
      @refetch-data="refetchData"
    /> -->
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
} from "bootstrap-vue";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { numberFormat, formatDateTime } from "@core/utils/filter";
import useAgencyRadioList from "@/views/marketing/useAgencyRadioList";
import marketingStoreModule from "@/views/marketing/marketingStoreModule";
import baseUrl from "@/libs/base-url";

export default {
  components: {
    // AddAgencyRadioModal: () => import("./AddAgencyRadioModal.vue"),
    // UpdateAgencyRadioModal: () => import('./UpdateAgencyRadioModal.vue'),
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      agencyRadioDetail: null,
    };
  },
  methods: {
    // addAgencyRadio() {
    //   this.$bvModal.show("modal-add-agency-radio");
    // },
    // deleteAgencyRadio(agencyRadioId) {
    //   this.$swal({
    //     title: "Are you sure ?",
    //     text: "You won't be able to revert this!",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonText: "Yes, delete it!",
    //     customClass: {
    //       confirmButton: "btn btn-primary",
    //       cancelButton: "btn btn-outline-danger ml-1",
    //     },
    //     buttonsStyling: false,
    //   }).then((result) => {
    //     if (result.value) {
    //       const agencyRadioData = {
    //         id: agencyRadioId,
    //       };
    //       store
    //         .dispatch("marketing/deleteAgencyRadio", agencyRadioData)
    //         .then((response) => {
    //           if (response.data.code == "00") {
    //             this.$swal({
    //               icon: "success",
    //               title: "Deleted!",
    //               text: "Your item has been deleted.",
    //               customClass: {
    //                 confirmButton: "btn btn-success",
    //               },
    //             });
    //             this.refetchData();
    //           }
    //         });
    //     }
    //   });
    // },
    // updateAgencyRadio(data) {
    //   this.agencyRadioDetail = data;
    //   this.$bvModal.show("modal-update-agency-radio");
    // },
  },
  setup() {
    const MARKETING_MODULE_NAME = "marketing";
    if (!store.hasModule(MARKETING_MODULE_NAME))
      store.registerModule(MARKETING_MODULE_NAME, marketingStoreModule);
    onUnmounted(() => {
      if (store.hasModule(MARKETING_MODULE_NAME))
        store.unregisterModule(MARKETING_MODULE_NAME);
    });
    const {
      fetchAgencyRadio,
      tableColumns,
      perPage,
      currentPage,
      totalAgencyRadio,
      dataMeta,
      refAgencyRadioListTable,
      refetchData,
      resolveStatus,
    } = useAgencyRadioList();

    return {
      fetchAgencyRadio,
      tableColumns,
      perPage,
      currentPage,
      totalAgencyRadio,
      dataMeta,
      refAgencyRadioListTable,
      refetchData,
      resolveStatus,
      numberFormat,
      formatDateTime,
      baseUrl,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>